import { useContext } from "react";
import type { NextPage } from "next";
import Container from "element/Container";

import { ContentCard, GridContainer, GridItem, H2, Loading } from "element";
import * as routes from "routes";
import UserContext from "context/UserContext";
import { useRouter } from "next/router";
import LoginForm from "component/forms/LoginForm";
import PageWithoutNav from "layouts/PageWithoutNav";

const Home: NextPage = () => {
  const router = useRouter();
  const userContext = useContext(UserContext);
  const { user, logout } = userContext;

  if (user) {
    router.push(routes.dashboardPath());
    return <Loading />;
  }

  return (
    <PageWithoutNav>
      <Container maxWidth="sm">
        <ContentCard>
          <GridContainer>
            <GridItem>
              <H2>Log In</H2>
            </GridItem>
            <GridItem>
              <LoginForm />
            </GridItem>
          </GridContainer>
        </ContentCard>
      </Container>
    </PageWithoutNav>
  );
};

export default Home;
