import { ReactNode } from "react";
import Logo from "component/Logo";

import { styled } from "@mui/material/styles";
import { Link } from "element";
import { Client } from "types";

const Root = styled("div")(
  ({ theme }) => `
  background: ${theme.palette.backgroundGradient};
  height: 100vh;
  width: 100vw;
`
);

const StyledHeader = styled("header")(
  ({ theme }) => `
  padding: ${theme.spacing(4)};
  text-align: center;
`
);

const StyledMain = styled("main")(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
`
);

export default function PageWithoutNav({
  endInsuredClient,
  children,
}: {
  endInsuredClient?: Client;
  children: ReactNode;
}) {
  return (
    <Root>
      <StyledHeader>
        <Link href="/">
          <Logo endInsuredClient={endInsuredClient} />
        </Link>
      </StyledHeader>
      <StyledMain>{children}</StyledMain>
    </Root>
  );
}
